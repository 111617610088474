
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "CommonQuestionnaireTemplateComment",
  props: ["course", "commonComment"],
  methods: {
    parseLinks(text: string) {
      return text
        .replace(
          /\b(([\w-]+:\/\/?|www[.])[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|)))/g,
          '<a style="color: rgb(0, 89, 255);" href="$1">$1</a>'
        )
        .replace(/([*].+?[*])/g, "<strong>$1</strong>")
        .replace(/\*/g, "");
    },
  },
});
