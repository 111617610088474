import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7919c83a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "comment--container" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(["space", _ctx.course])
  }, [
    (_ctx.course === 'one')
      ? (_openBlock(), _createElementBlock("section", {
          key: 0,
          class: _normalizeClass(["analysis--header red", _ctx.course])
        }, " Итоговый комментарий эксперта: ", 2))
      : _createCommentVNode("", true),
    (_ctx.course === 'two')
      ? (_openBlock(), _createElementBlock("section", {
          key: 1,
          class: _normalizeClass(["analysis--header red", _ctx.course])
        }, " Итоговый комментарий эксперта ", 2))
      : _createCommentVNode("", true),
    _createElementVNode("section", {
      class: _normalizeClass(["comment", _ctx.course])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("pre", {
          style: {"white-space":"pre-line","line-height":"16px"},
          class: "commentLinks",
          innerHTML: _ctx.parseLinks(_ctx.commonComment)
        }, null, 8, _hoisted_2)
      ])
    ], 2)
  ], 2))
}